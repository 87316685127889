<!--设备模块--空调舒适控制页-->
<template>
  <div id="controllIntermittent" ref="controllIntermittent">
    <div class="aside">
      <a-spin :spinning="spinning" style="width: 100%; height: 100%">
        <my-navder
          :list="list"
          @select="getMsgFormSon"
          :style="style"
        ></my-navder>
      </a-spin>
    </div>
    <div class="right">
      <a-spin :spinning="loading" class="main-spinning" v-if="id">
        <div class="main">
          <my-headertitle>{{this.$t('controllGroupComfort.a1')}}</my-headertitle>
          <div class="info">
            <my-nameupdate
              :title="nodeParentName"
              :name="nodeName"
              @confirm="nameUpdate"
              :state="state"
              :loading="loadingOK"
              ref="children"
            ></my-nameupdate>
            <div>
              <span>{{this.$t('controllGroupComfort.a2')}}</span>
              <span>{{ nodeId }}</span>
            </div>
          </div>
          <my-tabletitle>{{this.$t('controllGroupComfort.a3')}}</my-tabletitle>
          <my-table :data="monitorList"></my-table>
          <my-tabletitle>{{this.$t('controllGroupComfort.a4')}}</my-tabletitle>
          <my-table :data="measureList"></my-table>
          <my-tabletitle>{{this.$t('controllGroupComfort.a10')}}</my-tabletitle>
          <my-conditionCtrlTable :condition="ctrlCondition" :seasonList="seasonList"></my-conditionCtrlTable>
        </div>
      </a-spin>
      <div class="main-show" v-else>
        <a-icon type="select" style="font-size: 60px; margin-bottom: 20px" />
        <span>{{this.$t('controllGroupComfort.a5')}}</span>
      </div>
      <div class="footer" v-if="id">
        <a-button type="primary" @click="Setting">{{this.$t('controllGroupComfort.a11')}}</a-button>
      </div>
    </div>    
  </div>
</template>

<script>
import tabletitle from "../../components/Title/tabletitle";
import navdar from "../../components/Navdar/vertical";
import headerTitle from "../../components/Title/headerTitle";
import deviceTable from "./deviceTable";
import conditionCtrlTable from "./conditionCtrlTableComfort";
import nameUpdate from "../../components/Name/nameUpdate";
import { lightTimetableListKT,getComfortCtrlInfo,updateScheduleName,updateComfortCtrlInfo } from "../../api/device";
import select from "../../components/Select/select";

export default {
  data() {
    return {
      list: [],
      seasonList:[],
      seasonData:[],
      arr: [],
      MAX_NUM: 1,
      timer: null,
      carriedOut: true,
      pos: 0,
      spinning: false,
      id: undefined,//包含设备ID和时间表ID
      dvId:undefined,//设备ID
      nodeId: undefined,//时间表ID
      loading: false,
      schType: 9,      
      nodeName: undefined,//树节点名称
      nodeParentName: undefined,//父节点名称
      monitorList:[],
      measureList:[],
      ctrlCondition:{},
      visible: false,
      confirmLoading: false,
      copyKey: undefined,
      state: false,
      isActive: undefined,
      loadingOK: false,
    };
  },
  // 获取侧边栏数据同时执行虚拟化列表的方法
  async mounted() {
    this.spinning = true;
    // 进入页面时调用获取页面左侧列表方法
    this.navderList();    
    this.spinning = false;
  },
  computed: {
    // 侧边栏的高度计算和padding-top计算
    style: function () {
      if (this.pos !== 0) {
        return `height:${48 * this.arr.length}px;padding-top:${
          this.pos * 48 - 96
        }px`;
      } else {
        return `height:${48 * this.arr.length}px;padding-top:0px`;
      }
    },
  },
  watch: {
    // 监听侧边栏选择的id，并加载页面数据
    id: function (newQuestion, oldQuestion) {
      this.loading = true;      
      let par = {
        comfortId: this.nodeId,
      };
      this.getdata(par);      
    },
    p1: function () {
      this.selectList = this.arr.slice(this.p1, this.p2);
    },
    '$route'(to,from){
      this.spinning = true;
      // 进入页面时调用获取页面左侧列表方法
      this.navderList();    
      this.spinning = false;
    },
  },
  methods: {
    // 获取页面左侧列表方法
    navderList() {
      let name = this.$route.name;
      console.log("controllComfort route  name",name,name.indexOf('FAC_SAITEKI'));  
      this.schType = undefined;
      // if(name=="VRFAC_FAC_SAITEKI" || name=="LIGHTING_FAC_SAITEKI"||name=="ENVIRONMENT_FAC_SAITEKI"||name=="FACILITIES_FAC_SAITEKI")
      if(name.indexOf('FAC_SAITEKI')>=0)  
          this.schType=10;

      if(!this.schType){
        return;
      }

      //获取季节切换控制项列表
      let par = {
        siteId: this.$route.query.id,
        schType: this.schType,
      };

      this.spinning = true;
      lightTimetableListKT(par)
        .then((res) => {
          this.spinning = false;
          let { data } = res;
          data.key = "sub1";
          this.list.length=0;
          this.list.push(data);          
        })
        .catch((err) => {
          this.spinning = false;
          console.log(err);
        });

      //获取季节切换控制列表
      let parSeason = {
        siteId: this.$route.query.id,
        schType: 8,
      };
      lightTimetableListKT(parSeason)
        .then((res) => {
          this.spinning = false;
          let { data } = res;       
          this.seasonData=data;            
          console.log("lightTimetableListKT seasonData:",this.seasonData);               
        })
        .catch((err) => {
          this.spinning = false;
          console.log(err);
        });
    },
    //取选择项对应的季节切换控制列表
    getSeasonList(){
      if(this.seasonData!=null&&this.seasonData.children!=null){  
        let pKeys = null;          
        for(let i=0;i<this.seasonData.children.length;i++){
          pKeys = this.seasonData.children[i].key.split(",");
          if(pKeys[2]==this.dvId){ 
            if(this.seasonData.children[i].children!=null){
              let seasonListJSON = JSON.stringify(this.seasonData.children[i].children);
              this.seasonList = JSON.parse(seasonListJSON);                
              let val = null;
              for(let i=0;i<this.seasonList.length;i++){
                val = this.seasonList[i];
                let index = val.key.indexOf(",");
                if(index>0)
                  val.key = val.key.substring(index+1);//进取ID 
              }
            } 
          }
        }
      } 
      console.log("getSeasonList seasonList：",this.seasonList); 
    },
    // 加载页面数据的方法    
    getdata(par) {
      this.loading = true;      
      getComfortCtrlInfo(par)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          console.log("getComfortCtrlInfo--加载数据：",data);
          this.monitorList=data.monitorList;
          this.measureList=data.measureList;
          this.ctrlCondition=data.ctrlCondition;          
          for (let i = 0; i < this.monitorList.length; i++) {
            this.monitorList[i].key = i + 1;
          }
          for (let i = 0; i < this.measureList.length; i++) {
            this.measureList[i].key = i + 1;
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },    
    // 点击侧边栏获取页面详细数据加载id
    getMsgFormSon(data) {
      this.id = data;
      let keys = this.id.split(",");
      this.dvId=keys[0];
      this.nodeId = keys[1];      
      if(this.list[0].children!=null&&this.list[0].children.length>0){
        let pKeys=null;
        let schedule =null;
        for(let j=0;j<this.list[0].children.length;j++){
          pKeys=this.list[0].children[j].key.split(",");
          if(pKeys!=null&&pKeys.length>3){
            if(pKeys[2]==this.dvId){//同一设备ID
              schedule = this.list[0].children[j].children;
              this.nodeParentName = this.list[0].children[j].title;
              for(let i=0;i<schedule.length;i++){
                  if(this.id == schedule[i].key){
                    this.nodeName = schedule[i].title;
                    break;
                  }
              }
            }
          }
        }  
      }  
      //取季节切换控制列表
      this.getSeasonList();        
    },

    // 设置
    Setting() {
      this.loadingOK = true;
      let par = {
        id: this.nodeId,
        ctrlCondition: this.ctrlCondition,
      };
      console.log("updateComfortCtrlInfo--par：",par);
      updateComfortCtrlInfo(par)
        .then((res) => {
          this.loadingOK = false;
          if (res.errorCode == "00") {
              this.$message.success(res.msg);
            } else if (res.errorCode == "02") {
              this.$message.error(res.msg);
            }
        })
        .catch((err) => {
          console.log(err);
        });
    },        
    // 更新名称
    nameUpdate(name) {
      this.loadingOK = true;
      let par = {
        scheId: this.nodeId,
        scheType: this.schType,
        newName:name,
      };
      updateScheduleName(par)
        .then((res) => {
          this.loadingOK = false;
          this.$refs.children.changestate();
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  components: {
    "my-tabletitle": tabletitle,
    "my-navder": navdar,
    "my-headertitle": headerTitle,
    "my-table":deviceTable,
    "my-conditionCtrlTable":conditionCtrlTable,
    "my-nameupdate": nameUpdate,
    "my-select": select,
  },
};
</script>
<style scoped>
#controllIntermittent {
  height: 100%;
  display: flex;
}
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
.right {
  width: calc(100% - 250px);
  display: flex;
  flex-direction: column;
}
.aside {
  width: 250px;
  height: 100%;
  overflow: auto;
}
.main {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.main-spinning {
  width: 100%;
  height: 100%;
  padding: 0px 20px 20px 20px;
  overflow: auto;
}
.main-show {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: rgba(12, 12, 12, 0.3);
  letter-spacing: 5px;
  white-space: nowrap;
}
.info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  font-size: 14px;
  margin-bottom: 20px;
  white-space: nowrap;
  overflow: hidden;
}
.info-input {
  width: 120px;
  margin-right: 15px;
}
.info-button {
  width: 80px;
  margin: 0px 10px;
}
.info-hidden {
  display: flex;
  height: 60px;
  align-items: center;
}
.footer {
  height: 55px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid rgba(12, 12, 12, 0.1);
  padding: 10px 10px;
}
button {
  /* width: 120px; */
  margin: 0px 10px;
}
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
.d {
  /* display: flex; */
  position: relative;
}
.c {
  position: absolute;
  width: 600px;
}
</style>