<!--公共模块--空调舒适性控制条件表格-->
<template>
  <a-table :columns="columns" :data-source="conditions" :row-key="record => record.id">         
    <span slot="season" slot-scope="record">
      <a-select v-model="record.nowSeason" style="width: 160px" @change="handleChange">
        <a-select-option v-for="item in seasonList" :key="item.key" :value="item.key">
            {{ item.title }}
          </a-select-option>
      </a-select>
    </span>
    <span slot="summerTemperature" slot-scope="record">      
      <a-input :addon-before="$t('controllIntermittent.a15')" addon-after="℃" v-model="record.summerStartTemperature" style="width: 160px"/>
      <a-input :addon-before="$t('controllIntermittent.a16')" addon-after="℃" v-model="record.summerStopTemperature" style="width: 160px"/>
    </span>    
    <span slot="winterTemperature" slot-scope="record">      
      <a-input :addon-before="$t('controllIntermittent.a15')" addon-after="℃" v-model="record.winterStartTemperature" style="width: 160px"/>
      <a-input :addon-before="$t('controllIntermittent.a16')" addon-after="℃" v-model="record.winterStopTemperature" style="width: 160px"/>
    </span>    
  </a-table>
</template>
<script>



export default {  
  props: {
    condition: {
      type: Object,
    },
    seasonList: {
      type: Array,
    },    
  },
  data() {
    return {
      columns:[  
        {
          title: this.$t('controllIntermittent.a20'),///'季节分组',
          key: 'nowSeason',
          scopedSlots: { customRender: 'season' },
        },
        {
          title: this.$t('controllIntermittent.a21'),///'夏季目标温度',
          key: 'summerTemperature',
          scopedSlots: { customRender: 'summerTemperature' },
        },
        {
          title: this.$t('controllIntermittent.a22'),///'冬季目标温度',
          key: 'winterTemperature',
          scopedSlots: { customRender: 'winterTemperature' },
        },
      ],
      radioValue:1,
      conditions:[this.condition,],
    };
  },
  watch: {
    condition (val, oldVal) {
      if (val !== oldVal) {
        this.conditions.length=0;
        val.key = 1;
        this.conditions.push(val);
      }
      console.log("condition:",this.conditions);
    },   
  },
  methods: {
    onChange(e) {
      console.log('radio checked', e.target.value);
    },
    handleChange(e){
      console.log('select changed', e);
    }
  },
};
</script>
